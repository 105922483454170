/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { Flex } from "@theme-ui/components"
import { useMedia } from "react-use"

import { mediaQueryList } from "../helpers"

import { useIsClient } from "../hooks/use-is-client"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import ContentCard from "../components/content-card"
import CTA from "../components/cta"
import DesignPartnerDetails from "../components/design-partner-details"
import ProjectCard from "../components/project-card"
import Row from "../components/row"
import SEO from "../components/seo"
import SpecificationsDetails from "../components/specifications-details"
import SpecificationsOverview from "../components/specifications-overview"
import Text from "../components/text"

const OtherProjectsSection = ({
  projectDataList,
  projectModelTitle,
  ...restProps
}) => {
  const isClient = useIsClient()
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const renderProjectCard = projectData => {
    return (
      <ProjectCard
        background={projectData.projectImages[0].file.url}
        link={{
          to: `/projects/${projectData.slug}`,
          type: "internal",
        }}
        text={[projectData.projectTitle.projectTitle].concat(
          projectData.projectLocation.projectLocation
        )}
        type="tertiary"
      />
    )
  }
  const renderProjectList = () => {
    let projectListOutput = null

    if (!isClient || (isClient && mediaQueryMatch)) {
      projectListOutput = projectDataList.slice(0, 2).map(projectData => (
        <Column size={[12, 12, 6]} key={projectData.id}>
          {renderProjectCard(projectData)}
        </Column>
      ))
    } else {
      projectListOutput = (
        <Column>
          <Carousel type="tertiary">
            {projectDataList.map(projectData => (
              <div key={projectData.id}>{renderProjectCard(projectData)}</div>
            ))}
          </Carousel>
        </Column>
      )
    }

    return projectListOutput
  }

  return projectDataList.length ? (
    <Container
      customStyle={{
        pt: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
        pb: theme => [
          theme.spacing.vertical.sm,
          theme.spacing.vertical.sm,
          theme.spacing.vertical.lg,
        ],
      }}
      {...restProps}
    >
      <Row>
        <Column>
          <Text type="h2">
            Other {projectModelTitle}
            <br />
            Inspired Projects
          </Text>
        </Column>
      </Row>
      <Row
        customStyle={{
          mt: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.lg,
          ],
        }}
      >
        {renderProjectList()}
      </Row>
    </Container>
  ) : null
}
const ProjectModelButton = ({
  configuratorUrl,
  modelSlug,
  modelTitle,
  ...restProps
}) => (
  <div {...restProps}>
    <CTA type="secondary" link={configuratorUrl} linkType="internal">
      Customized {modelTitle}
    </CTA>
  </div>
)

const ProjectDetail = ({ data, location }) => {
  const { contentfulProject: project } = data

  return (
    <React.Fragment>
      <SEO title={project.projectTitle.projectTitle} />
      <Container>
        <Row>
          <Column>
            {project.model && project.model.configuratorUrl && (
              <ProjectModelButton
                sx={{
                  mt: theme => theme.spacing.vertical.sm,
                  textAlign: "right",
                }}
                configuratorUrl={project.model.configuratorUrl}
                modelSlug={project.model.slug}
                modelTitle={project.model.title}
              />
            )}
            <SpecificationsOverview
              pathname={location.pathname}
              tableContent={[
                {
                  bodyText: project.projectLocation.projectLocation,
                  headingText: "Location",
                },
                {
                  bodyText: project.client,
                  headingText: "Client",
                },
                {
                  bodyText:
                    project.beds &&
                    project.baths &&
                    `${project.beds} Bed | ${project.baths} Bath`,
                  headingText: "Layout",
                },
                {
                  bodyText: project.size && `${project.size} sq. ft.`,
                  headingText: "Size",
                },
              ]}
              title={project.projectTitle.projectTitle}
            />
          </Column>
        </Row>
      </Container>
      <Container fullWidth={[true, true, true]}>
        <Row noGutters={[true, true, true]}>
          <Column noGutters={[true, true, true]}>
            <Img
              sx={{ height: [250, 250, 700] }}
              alt={project.projectImages[0].title}
              fluid={project.projectImages[0].fluid}
            />
          </Column>
        </Row>
      </Container>
      <SpecificationsDetails
        sx={{
          pt: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.lg,
          ],
        }}
        awards={project.awards}
        description={
          project.projectDescription && project.projectDescription.json
        }
        inTheNews={project.inTheNews}
        richText={project.factoid && project.factoid.json}
        tableContent={[
          {
            bodyText: project.beds && `${project.beds}`,
            headingText: "Bedrooms",
          },
          {
            bodyText: project.baths && `${project.baths}`,
            headingText: "Bathrooms",
          },
          {
            bodyText: project.size && `${project.size} sq. ft.`,
            headingText: "Floor Area",
          },
          {
            bodyText: project.numberOfModules && `${project.numberOfModules}`,
            headingText: "Modules",
          },
          {
            bodyText: project.assembly && project.assembly.assembly,
            headingText: "Assembly",
          },
        ]}
      />
      {project.featuredImages3Only &&
        project.featuredImages3Only.length &&
        project.featuredImages3Only.length === 3 && (
          <Container
            customStyle={{
              pt: theme => [
                theme.spacing.vertical.md,
                theme.spacing.vertical.md,
                theme.spacing.vertical.lg,
              ],
              pb: theme => theme.spacing.vertical.lg,
            }}
          >
            <Row>
              <Column size={[12, 12, 6]}>
                <div sx={{ borderRadius: 5, overflow: "hidden" }}>
                  <Img
                    fluid={project.featuredImages3Only[0].fluid}
                    alt={project.featuredImages3Only[0].title}
                  />
                </div>
              </Column>
              <Column size={[12, 12, 6]}>
                <Row
                  customStyle={{
                    height: "100%",
                    pt: theme => [
                      theme.spacing.vertical.sm / 3,
                      theme.spacing.vertical.sm / 3,
                      0,
                    ],
                  }}
                >
                  <Column>
                    <div
                      sx={{
                        borderRadius: 5,
                        height: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <Img
                        sx={{ height: "100%" }}
                        fluid={project.featuredImages3Only[1].fluid}
                        alt={project.featuredImages3Only[1].title}
                      />
                    </div>
                  </Column>
                </Row>
                <Row
                  customStyle={{
                    height: "100%",
                    pt: theme => [
                      theme.spacing.vertical.sm / 3,
                      theme.spacing.vertical.sm / 3,
                      theme.spacing.horizontal,
                    ],
                  }}
                >
                  <Column>
                    <div
                      sx={{
                        borderRadius: 5,
                        height: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <Img
                        sx={{ height: "100%" }}
                        fluid={project.featuredImages3Only[2].fluid}
                        alt={project.featuredImages3Only[2].title}
                      />
                    </div>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Container>
        )}
      {project.quote && project.quote.quote && (
        <Container>
          <Row>
            <Column>
              <div sx={{ maxWidth: 640, mx: "auto" }}>
                {documentToReactComponents(project.quote.quote.json, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <Text
                        customStyle={{
                          fontStyle: "italic",
                          fontSize: [3, 3, 5],
                        }}
                      >
                        {children}
                      </Text>
                    ),
                  },
                })}
                {(project.quote.image || project.quote.name) && (
                  <Flex
                    sx={{
                      alignItems: "center",
                      mt: theme => theme.spacing.vertical.sm,
                    }}
                  >
                    {project.quote.image && (
                      <div
                        sx={{
                          borderRadius: "100%",
                          height: 75,
                          mr: 3,
                          overflow: "hidden",
                          width: 75,
                        }}
                      >
                        <img
                          sx={{
                            height: "100%",
                            objectFit: "cover",
                            width: "100%",
                          }}
                          src={project.quote.image.file.url}
                          alt={project.quote.image.title}
                        />
                      </div>
                    )}
                    {project.quote.name && (
                      <Text
                        customStyle={{
                          fontSize: 17,
                          fontWeight: "heading",
                        }}
                      >
                        {project.quote.name}
                      </Text>
                    )}
                  </Flex>
                )}
              </div>
            </Column>
          </Row>
        </Container>
      )}
      {project.model && project.model.model__variation_ && (
        <React.Fragment>
          <Container
            customStyle={{ mt: theme => theme.spacing.vertical.lg }}
            fullWidth={[true, true, true]}
          >
            <Row noGutters={[true, true, true]}>
              <Column noGutters={[true, true, true]}>
                <Carousel type="tertiary">
                  {project.projectImages
                    .slice(1, project.projectImages.length)
                    .map(projectImage => (
                      <Img
                        sx={{ height: [300, 400, 700] }}
                        alt={projectImage.title}
                        fluid={projectImage.fluid}
                        key={projectImage.id}
                      />
                    ))}
                </Carousel>
              </Column>
            </Row>
          </Container>
          <Container
            customStyle={{
              mt: theme => theme.spacing.vertical.md,
              pb: theme => [
                theme.spacing.vertical.md,
                theme.spacing.vertical.md,
                theme.spacing.vertical.lg,
              ],
            }}
          >
            <Row customStyle={{ justifyContent: "space-between" }}>
              <Column size={[12, 12, 5, 4]}>
                <Text type="h2">
                  Project design based off the {project.model.title}
                </Text>
                <Text customStyle={{ mt: 2 }}>
                  This project was inspired by one of our LivingHomes. Explore
                  the original model and see a full list of available layouts
                  and options.
                </Text>
                {project.model.configuratorUrl && (
                  <ProjectModelButton
                    sx={{ mt: theme => theme.spacing.vertical.md }}
                    configuratorUrl={project.model.configuratorUrl}
                    modelSlug={project.model.slug}
                    modelTitle={project.model.title}
                  />
                )}
              </Column>
              <Column
                customStyle={{
                  mt: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    0,
                  ],
                }}
                size={[12, 12, 6, 7]}
              >
                <Carousel type="tertiary">
                  {project.model.model__variation_.map(modelVariationData => (
                    <ContentCard
                      baths={modelVariationData.baths}
                      beds={modelVariationData.beds}
                      imageList={[modelVariationData.images[0]]}
                      squareFootage={modelVariationData.squareFootage}
                      title={modelVariationData.name}
                      titleLink={{
                        to: `/models/${project.model.slug}`,
                        type: "internal",
                      }}
                      key={modelVariationData.id}
                    />
                  ))}
                </Carousel>
              </Column>
            </Row>
          </Container>
        </React.Fragment>
      )}
      {project.model && project.model.designPartner && (
        <div
          sx={{
            bg: theme => theme.colors.backgrounds.primary,
            pt: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
            pb: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <DesignPartnerDetails
            currentModelMasterSlug={project.model.slug}
            name={project.model.designPartner.name}
            photo={project.model.designPartner.photo}
            relatedModelMasterList={project.model.designPartner.model__master_}
            shortBio={
              project.model.designPartner.shortBio &&
              project.model.designPartner.shortBio.json
            }
            slug={project.model.designPartner.slug}
          />
        </div>
      )}
      {project.model && project.model.project && (
        <OtherProjectsSection
          projectDataList={project.model.project.filter(
            projectData => projectData.slug !== project.slug
          )}
          projectModelTitle={project.model.title}
        />
      )}
    </React.Fragment>
  )
}

export default ProjectDetail

export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      assembly {
        assembly
      }
      awards {
        awardLogo {
          file {
            url
          }
          id
          title
        }
        id
        title
      }
      baths
      beds
      client
      designPartner {
        name
      }
      factoid {
        json
      }
      featuredImages3Only {
        fluid(maxWidth: 650) {
          ...GatsbyContentfulFluid_withWebp
        }
        id
        title
      }
      inTheNews {
        link
        publicationLogo {
          file {
            url
          }
          id
          title
        }
        publicationTitle
      }
      model {
        configuratorUrl
        designPartner {
          shortBio {
            json
          }
          photo {
            fluid(maxWidth: 150) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          model__master_ {
            id
            model__variation_ {
              baths
              beds
              id
              images {
                fluid(maxWidth: 800) {
                  ...GatsbyContentfulFluid_withWebp
                }
                id
                title
              }
              name
              squareFootage
            }
            slug
            title
          }
          name
          slug
        }
        model__variation_ {
          baths
          beds
          id
          images {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          name
          squareFootage
        }
        project {
          id
          projectImages {
            file {
              url
            }
            id
            title
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
        }
        slug
        title
      }
      numberOfModules
      projectDescription {
        json
      }
      projectImages {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
        id
        title
      }
      projectLocation {
        projectLocation
      }
      projectTitle {
        projectTitle
      }
      quote {
        image {
          file {
            url
          }
          title
        }
        name
        quote {
          json
        }
      }
      size
      slug
    }
  }
`
